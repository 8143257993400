import request from '@/utils/request'

export function updateQuantity(parameter) {
  return request({
    url: '/wealth/quota/add',
    method: 'post',
    data: parameter
  })
}

export function updateOrUpdateQuantity(parameter) {
  return request({
    url: '/wealth/quota/update',
    method: 'post',
    data: parameter
  })
}

export function queryQuantity(parameter) {
  return request({
    url: '/wealth/quota/page',
    method: 'get',
    params: parameter
  })
}

export function queryQuantityOrder(parameter) {
  return request({
    url: '/wealth/quota/order/page',
    method: 'get',
    params: parameter
  })
}

export function queryQuantityUpdate(parameter) {
  return request({
    url: '/wealth/quota/record',
    method: 'get',
    params: parameter
  })
}

export function getQuantity(parameter) {
  return request({
    url: '/wealth/quota/detail',
    method: 'get',
    params: parameter
  })
}

export function getQuantityDashboard(parameter) {
  return request({
    url: '/wealth/quota/statistics',
    method: 'get',
    params: parameter
  })
}

export function cancelQuantityOrder(parameter) {
  return request({
    url: '/wealth/quota/order/cancel/' + parameter.id,
    method: 'post',
  })
}


